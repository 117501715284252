import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../css/global.css";
import styled from "styled-components";
import { motion } from "framer-motion";
import Banner from "../components/banner/banner";
import InnerWrapper from "../components/inner-wrapper/inner-wrapper";
import CtaGradient from "../components/cta-gradient/cta-gradient";
import ColouredBoxList from "../components/coloured-box-list/coloured-box-list";
import AnimatedLogo from "../components/animated-logo/animated-logo";
import VideoLightbox from "../components/lightbox/video";
import {
  mainBlack,
  mainWhite,
  whileInVew,
  screen,
} from "../components/variables/variables";

const Wrapper = styled.div`
  .intro {
    background: ${mainBlack};
    color: ${mainWhite};
    padding: 45px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }

    .flex-row {
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .col {
        &--left {
          margin: 0 0 30px 0;
          @media ${screen.small} {
            margin: 0;
            max-width: 500px;
            padding: 0 70px 0 0;
          }
        }
      }
    }
  }

  .featured {
    background-image: ${(props) => `url(${props.backgroundImageFeatured})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 60px 0 245px 0;
    position: relative;
    @media ${screen.xsmall} {
      padding: 180px 0 295px 0;
    }

    .txt-container {
      max-width: 550px;
      color: ${mainWhite};
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      background: rgba(30, 30, 30, 0.55);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .cta {
    position: relative;
    height: 75px;
  }

  .result {
    padding: 80px 0 30px 0;
    @media ${screen.xsmall} {
      padding: 120px 0 90px 0;
    }

    .flex-row {
      @media ${screen.small} {
        display: flex;
      }

      .col {
        &--left {
          @media ${screen.small} {
            max-width: 370px;
            width: 100%;
          }
        }

        &--right {
          margin: 30px 0 0 0;
          @media ${screen.small} {
            padding: 0 0 0 40px;
            margin: 0;
          }

          .item-list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 -10px;
            @media ${screen.xsmall} {
            }

            &__each {
              width: calc(50% - 20px);
              margin: 0 10px 20px 10px;
              border-radius: 8px;
              background-color: ${mainWhite};
              box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.25);
              text-align: center;
              padding: 21px 14px;
              @media ${screen.xsmall} {
                width: calc(33.33% - 20px);
              }
              @media ${screen.medium} {
                width: calc(20% - 20px);
              }

              .icon {
                display: block;
                width: 39px;
                height: 39px;
                margin: 0 auto 10px auto;
                @media ${screen.xsmall} {
                  margin: 0 auto 12px auto;
                  width: 45px;
                  height: 45px;
                }
              }

              .label {
                font-size: 0.72rem;
                font-weight: 300;
                @media ${screen.xsmall} {
                  font-size: 0.82rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const [isVideo, setIsVideo] = useState(false);

  const {
    banner_background_image,
    banner_background_image_mobile,
    banner_title,
    banner_description,
    banner_button_label,
    banner_embedded_video_wistia,
    intro_heading,
    intro_description,
    intro_box_list,
    featured_heading,
    featured_description,
    featured_background_image,
    cta_heading,
    cta_description,
    cta_button_label,
    result_heading,
    result_description,
    result_box_list,
    title_tag,
    meta_description,
  } = data.content.data;

  return (
    <Layout>
      <Seo
        title={title_tag}
        description={meta_description}
        image={banner_background_image.thumbnails.og.url}
        isHomePage={true}
      />
      <Wrapper backgroundImageFeatured={featured_background_image.url}>
        <Banner
          noOverlay
          banner_background_image={banner_background_image}
          banner_background_image_mobile={banner_background_image_mobile}
          banner_title={banner_title}
          banner_description={banner_description}
          banner_button_label={banner_button_label}
          triggerFunction={
            banner_button_label && { func: setIsVideo, arg: true }
          }
        />

        <section className="intro">
          <InnerWrapper>
            <div className="flex-row" style={{ alignItems: 'flex-start' }}>
              <motion.div {...whileInVew} className="col col--left">
                <h2 className="heading">{intro_heading.text}</h2>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: intro_description.html }}
                />
              </motion.div>
              <motion.div {...whileInVew} className="col col--right">
                <ColouredBoxList list={intro_box_list} isHomePage={true} />
              </motion.div>
            </div>
          </InnerWrapper>

          <AnimatedLogo className="logo-bg" />
        </section>

        <section className="featured">
          <InnerWrapper>
            <motion.div {...whileInVew} className="txt-container">
              <h3 className="heading">{featured_heading.text}</h3>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: featured_description.html }}
              />
            </motion.div>
          </InnerWrapper>
        </section>

        <section className="cta">
          <CtaGradient
            cta_heading={cta_heading}
            cta_description={cta_description}
            cta_button_label={cta_button_label}
          />
        </section>

        <section className="result">
          <InnerWrapper>
            <div className="flex-row">
              <motion.div {...whileInVew} className="col col--left">
                <h5 className="heading">{result_heading.text}</h5>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: result_description.html }}
                />
              </motion.div>

              <motion.div {...whileInVew} className="col col--right">
                <ul className="item-list">
                  {result_box_list.map((item) => (
                    <li className="item-list__each" key={item.label}>
                      <img
                        className="icon"
                        aria-hidden="true"
                        src={item.icon.url}
                        alt={item.alt || item.label}
                      />
                      <span className="label">{item.label}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            </div>
          </InnerWrapper>
        </section>
      </Wrapper>
      <VideoLightbox
        isVideo={isVideo}
        setIsVideo={setIsVideo}
        htmlCode={banner_embedded_video_wistia}
      />
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePage {
      data {
        banner_embedded_video_wistia
        banner_background_image_mobile {
          url
        }
        banner_background_image {
          url
          thumbnails {
            og {
              url
            }
          }
        }
        banner_title {
          text
        }
        banner_description {
          html
        }
        banner_button_label
        intro_heading {
          text
        }
        intro_description {
          html
        }
        intro_box_list {
          background_colour_on_hover
          label
          icon {
            alt
            url
          }
        }
        featured_heading {
          text
        }
        featured_description {
          html
        }
        featured_background_image {
          url
        }
        cta_heading {
          text
        }
        cta_description {
          html
        }
        cta_button_label
        result_heading {
          text
        }
        result_description {
          html
        }
        result_box_list {
          label
          icon {
            alt
            url
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
