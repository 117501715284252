import React from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { mainBlack, mainWhite, screen } from "../variables/variables";
import { MdClose } from "react-icons/md";
import EmbedContainer from "react-oembed-container";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: -100 },
  show: { opacity: 1, y: 0 },
};

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  color: ${mainWhite};

  .inner-container {
    background: ${mainBlack};
    border-radius: 12px;
    max-width: 1080px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(100% - 20px);
    padding: 35px 25px;
    @media ${screen.xsmall} {
      padding: 60px;
      width: calc(100% - 60px);
    }
    @media ${screen.medium} {
      width: 100%;
    }

    .close-btn {
      line-height: 30px;
      background: none;
      border: 0;
      border-radius: none;
      color: ${mainWhite};
      font-size: 1.6rem;
      position: absolute;
      z-index: 4;
      right: 14px;
      top: 7px;
      cursor: pointer;
      padding: 0;
      @media ${screen.xsmall} {
        font-size: 2rem;
        right: 20px;
        top: 20px;
      }
    }
  }
`;

const Video = ({ isVideo, setIsVideo, htmlCode }) => {
  return (
    <AnimatePresence>
      {!!htmlCode && isVideo && (
        <Wrapper
          variants={container}
          initial="hidden"
          animate={isVideo ? "show" : "hidden"}
          exit={{ opacity: 0 }}
          aria-label="Overlay video"
        >
          <div className="inner-container">
            <EmbedContainer markup={htmlCode}>
              <motion.div
                variants={item}
                dangerouslySetInnerHTML={{
                  __html: htmlCode,
                }}
              />
            </EmbedContainer>
            <button
              className="close-btn"
              aria-label="Close video lightbox"
              onClick={() => setIsVideo(false)}
            >
              <MdClose />
            </button>
          </div>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default Video;
