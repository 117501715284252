import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { mainWhite, whileInVew, screen } from "../variables/variables";
import InnerWrapper from "../inner-wrapper/inner-wrapper";
import Button from "../button/button";
import ContactForm from "../lightbox/contact-form";

const Wrapper = styled.div`
  &.none-position {
      position: relative !important;
      bottom: 0;
  }
  color: ${mainWhite};
  z-index: 9;
  bottom: -50px;
  width: 100%;

  .flex-row {
    background: linear-gradient(
      80deg,
      rgba(42, 157, 106, 1) 34%,
      rgba(0, 158, 208, 1)
    );
    padding: 30px 30px 36px 30px;
    border-radius: 12px;
    box-shadow: 0 3px 14px rgba(30, 30, 30, 0.175);
    @media ${screen.xsmall} {
      padding: 35px 35px 40px 35px;
    }
    @media ${screen.small} {
      display: flex;
      align-items: center;
      padding: 35px 60px 40px 60px;
    }
  }

  .col {
    &--left {
      text-align: center;

      @media ${screen.small} {
        margin: 0 75px 0 0;
        max-width: 600px;
        text-align: left;
      }
      @media ${screen.medium} {
        margin: 0 150px 0 0;
      }
    }

    &--right {
      .btn {
        margin: 0 auto;
        @media ${screen.small} {
          margin: 0;
        }
      }
    }

    .description {
      margin: 0 0 26px 0;
      @media ${screen.small} {
        text-align: left;
        margin: 0;
      }

      a {
        font-weight: 600;
      }

      strong {
        font-weight: 600;
      }
    }
  }
`;

const CtaGradient = ({
  className,
  cta_heading,
  cta_description,
  cta_button_label,
  relative,
}) => {
  // only render if content provided
  const isBothAvailable = cta_heading && cta_description;

  const [isContactForm, setIsContactForm] = useState(false);

  const openContactForm = () => setIsContactForm(true);

  return (
    <>
      <Wrapper className={className} style={{ position: relative ? 'relative' : 'absolute', }}>
        {isBothAvailable && (
          <InnerWrapper>
            <div className="flex-row">
              <motion.div {...whileInVew} className="col col--left">
                <h4 className="heading heading--semi-bold">
                  {cta_heading.text}
                </h4>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: cta_description.html }}
                />
              </motion.div>

              <motion.div {...whileInVew} className="col col--right">
                <Button className="btn" onClick={openContactForm}>
                  {cta_button_label}
                </Button>
              </motion.div>
            </div>
          </InnerWrapper>
        )}
      </Wrapper>
      <ContactForm
        isContactForm={isContactForm}
        setIsContactForm={setIsContactForm}
      />
    </>
  );
};

export default CtaGradient;
